
.our_work_section .img_container {
    display: flex;
    justify-content: center;
}

.our_work_section img {
    height: 500px;
    width: 382px;
}

table {
    margin: 8rem 0 2rem;
}

table tr, table td, table th {
    border: 0.2px rgb(215, 209, 209) solid;
}

.banner {
  height: 20rem;
  width: 100%;
  background-repeat: round;
}

.banner img {
    object-fit: cover;
    width: 100%;
    height: 12.5rem;
}
.about_section {
	width: 100%;
	/* float: left; */
	/*background-color: #ffff;*/
	height: auto;
	/*padding: 90px 0px 40px 0px;*/
	/**margin: 3rem;*/
	padding: 45px 0;
}

.about_section .content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.about_section .img-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}




.about_text_main {
	width: 100%;
	float: left;
	/*padding: 100px 0px;*/
}

.about_taital {
	width: 100%;
	float: left;
	font-size: 40px;
	/*color: #ffffff;*/
	font-weight: bold;
	text-align: center;
}

.about_text {
	/* width: 100%;
    float: left;
    font-size: 16px;
    margin: 30px 0px 0px 0px; */
}
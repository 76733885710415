
.our_work_section {
    width: 100%;
	height: auto;
	padding: 45px 0;
}

.our_work_section .content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.about_section .img-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* 
.our_work_section .img_container {
    display: flex;
    justify-content: center;
}

.our_work_section img {
    height: 500px;
    width: 382px;
} */

/*--------------------------------------------------------------------- File Name: style.css ---------------------------------------------------------------------*/


/*--------------------------------------------------------------------- import Files ---------------------------------------------------------------------*/
/* 
@import url(animate.min.css);
@import url(normalize.css);
@import url(icomoon.css);
@import url(css/font-awesome.min.css);
@import url(meanmenu.css);
@import url(owl.carousel.min.css);
@import url(swiper.min.css);
@import url(slick.css);
@import url(jquery.fancybox.min.css);
@import url(jquery-ui.css);
@import url(nice-select.css); */

/*--------------------------------------------------------------------- skeleton ---------------------------------------------------------------------*/

* {
    box-sizing: border-box !important;
    transition: ease all 0.5s;
}

html {
    scroll-behavior: smooth;
}

body {
    color: #666666;
    font-size: 14px;
    font-family: 'Sen', sans-serif;
    line-height: 1.80857;
    font-weight: normal;
    overflow-x: hidden;
}

a {
    color: #1f1f1f;
    text-decoration: none !important;
    outline: none !important;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: 0;
    font-weight: normal;
    position: relative;
    padding: 0 0 10px 0;
    font-weight: normal;
    line-height: normal;
    color: #111111;
    margin: 0
}

h1 {
    font-size: 24px
}

h2 {
    font-size: 22px
}

h3 {
    font-size: 18px
}

h4 {
    font-size: 16px
}

h5 {
    font-size: 14px
}

h6 {
    font-size: 13px
}

*,
*::after,
*::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: #212121;
    text-decoration: none!important;
    opacity: 1
}

button:focus {
    outline: none;
}

ul,
li,
ol {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

p {
    margin: 20px;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 24px;
}

a {
    color: #222222;
    text-decoration: none;
    outline: none !important;
}

a, .btn {
    text-decoration: none !important;
    outline: none !important;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

img {
    max-width: 100%;
    height: auto;
}

 :focus {
    outline: 0;
}

.paddind_bottom_0 {
    padding-bottom: 0 !important;
}

.btn-custom {
    margin-top: 20px;
    background-color: transparent !important;
    border: 2px solid #ddd;
    padding: 12px 40px;
    font-size: 16px;
}

.lead {
    font-size: 18px;
    line-height: 30px;
    color: #767676;
    margin: 0;
    padding: 0;
}

.form-control:focus {
    border-color: #ffffff !important;
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .25);
}

.navbar-form input {
    border: none !important;
}

.badge {
    font-weight: 500;
}

blockquote {
    margin: 20px 0 20px;
    padding: 30px;
}

button {
    border: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.full {
    float: left;
    width: 100%;
}

.layout_padding {
    padding-top: 100px;
    padding-bottom: 0px;
}

.padding_0 {
    padding: 0px;
}


/* header section start */

.header_section {
    width: 100%;
    /* float: left; */
    /* background: linear-gradient(90deg, #001431 61%, #f8f2e2 61%);*/
    background: linear-gradient(90deg, #001431 60%, #f8f2e2 99%);
    height: auto;
    background-size: 100%;
    background-repeat: no-repeat;
    /* position: relative; */
}

.header_bg {
    background: #fb6818;
    border-bottom: 1px solid #ffffff;
}

.bg-light {
    background-color: transparent !important;
}

.mr-auto,
.mx-auto {
    text-align: center;
}

.ml-auto,
.mx-auto {
    margin: 0 auto;
}

a.navbar-brand {
    /*width: 24%;*/
    flex-grow: 1;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding: 3px 5px;
    font-size: 16px;
    color: #fefefd;
    margin: 0px 6px;
    border: 1px solid transparent;
    border-radius: 5px;
    text-transform: uppercase;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: #fefefd;
    border: 1px solid #fefefd;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #fefefd;
    border: 1px solid #fefefd;
}

.navbar-brand {
    margin: 0px;
    float: right;
    width: 14%;
}

.navbar {
    padding: 15px 15px;
}

.user_icon {
    padding-right: 15px;
}

.offcanvas-body{
    background-color: #001431;
}

.offcanvas-title {
    color: #001431;
}

.login_bt {
    width: 210px;
}

.login_bt ul {
    margin: 0px;
    padding: 0px;
}

.login_bt li a {
    float: left;
    font-size: 18px;
    color: #001431;
    text-transform: uppercase;
    padding: 0px 0px 0px 50px;
}

.login_bt li a:hover {
    color: #ffffff;
}


/* header section end */


/* banner section start */

.banner_section {
    width: 100%;
    float: left;
    padding: 100px 0px;
    /* background: linear-gradient(90deg, #001431 61%, #f8f2e2 61%); */
    /* background: #001431; */
    background: #630708;
}

.banner_taital_main {
    width: 100%;
    float: left;
    /*padding-left: 100px;*/
}

.banner_taital {
    width: 90%;
    font-size: 60px;
    color: #fefefd;
    text-transform: uppercase;
    font-weight: bold;
}

.banner_text {
    width: 100%;
    font-size: 16px;
    color: #fefefd;
    float: left;
    padding-top: 10px;
    margin: 0px;
}

.started_bt {
    width: 170px;
    float: left;
    margin-left: 15px;
    margin-top: 30px;
}

.started_bt button {
    width: 100%;
    float: left;
    padding: 8px 10px;
    color: #ffffff;
    background-color: #769f7a;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    border-radius: 5px;
}

.started_bt button:hover {
    color: #001431;
    background-color: #ffffff;
}

.readmore_btn {
    width: 170px;
    float: left;
    margin-top: 30px;
}

.readmore_btn button {
    width: 100%;
    float: left;
    padding: 8px 10px;
    color: #ffffff;
    background-color: #769f7a;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    border-radius: 5px;
}

.readmore_btn button:hover {
    color: #001431;
    background-color: #ffffff;
}

.readmore_btn.active button {
    color: #001431;
    background-color: #ffffff;
}

.banner_img {
    width: 58%;
    margin: 0 auto;
    text-align: center;
}

#banner_slider a.carousel-control-prev {
    left: initial;
    top: 180px;
    right: .1rem;
    color: #ffffff;
    background-color: #001431;
}

#banner_slider a.carousel-control-next {
    right: .1rem;
    top: 120px;
}

#banner_slider .carousel-control-next,
#banner_slider .carousel-control-prev {
    width: 55px;
    height: 55px;
    background: #769f7a;
    opacity: 1;
    font-size: 16px;
    color: #ffffff;
}

#banner_slider .carousel-control-next:focus,
#banner_slider .carousel-control-next:hover,
#banner_slider .carousel-control-prev:focus,
#banner_slider .carousel-control-prev:hover {
    color: #ffffff;
    background-color: #001431;
}


/* banner section end */


/* box section start */

.box_section {
    width: 100%;
    float: left;
    position: relative;
    top: -70px;
}

.online_box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.online_box_main {
    width: 100%;
    display: flex;
}

.box_left {
    width: auto;
}

.box_right {
    width: 70%;
    padding-left: 20px;
}

.right_arrow {
    width: 50px;
    font-size: 20px;
    color: #ffffff;
    background-color: #001431;
    border-radius: 100%;
    text-align: center;
    padding: 7px;
    height: 50px;
    justify-content: center;
    align-items: center;
}

.book_text {
    width: 100%;
    font-size: 16px;
    color: #769f7a;
    margin: 0px;
    font-weight: bold;
}

.appoinment_text {
    width: 100%;
    font-size: 20px;
    color: #001431;
    font-weight: bold;
    padding-bottom: 0px;
}

.online_box_left {
    width: 100%;
    float: left;
    background-color: #fefefd;
    height: auto;
    padding: 30px;
    box-shadow: 0px 9px 15px 5px #ededed;
    border-radius: 8px;
    margin: 10px;
    /*width: 300px;*/
}

.online_box_left:hover {
    background-color: #769f7a;
}

.online_box_left:hover .book_text {
    color: #ffffff;
}

.online_box_left:hover .appoinment_text {
    color: #ffffff;
}

.online_box_left.active {
    background-color: #769f7a;
}

.online_box_left.active .book_text {
    color: #ffffff;
}

.online_box_left.active .appoinment_text {
    color: #ffffff;
}


/* box section end */


/* services section start */

.services_section {
    width: 100%;
    float: left;
}

.services_section_2 {
    width: 100%;
    float: left;
    background-color: #f8f2e2;
    padding: 40px 0px;
    margin-top: 60px;
}

.services_section_2 .general_description {
    padding: 0px 0px 40px;
}

.service_box {
	width: 345px;
	padding: 10px;
	height: 430px;
}

.house_icon {
	display: flex;
    justify-content: center;
}

.corporate_text {
    width: 100%;
    float: left;
    /* color: #001431;
    font-size: 24px; */
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px 0px;
    text-align: center;
}

p.chunks_text {
	width: 100%;
	/* float: left; */
	font-size: 16px;
	margin: 0px;
	color: #001431;
	text-align: center;
}

.readmore_button {
    /* 
		width: 160px;
    float: left;
    margin-top: 30px; 
		*/
		text-align: center;
		/*margin: 20px 0;*/
}

.readmore_button button {
    width: 160px;
    /* float: left; */
    font-size: 16px;
    color: #ffffff;
    background-color: #001431;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
}

.service_box .house_icon .image_2 {
    display: none;
}

/* .service_box:hover .house_icon .image_2 {
    display: block;
    text-align: center;
} */

.service_box .house_icon .image_1 {
    display: block;
    width: 382px;
    height: 255px;
}

/* .service_box:hover .house_icon .image_1 {
    display: none;
} */

.service_box:hover .corporate_text {
    color: #769f7a;
}

.service_box:hover .readmore_button button {
    background-color: #769f7a;
    color: #ffffff;
}

.service_box.active .corporate_text {
    color: #769f7a;
}

.service_box.active .readmore_button button {
    background-color: #769f7a;
    color: #ffffff;
}

.our_work:hover .readmore_button button,
.price_section:hover .readmore_button button {
    background-color: #769f7a;
    color: #ffffff;
}

#main_slider a.carousel-control-prev {
    left: 40px;
    top: 130px;
}

#main_slider a.carousel-control-next {
    right: 40px;
    top: 130px;
}

#main_slider .carousel-control-next,
#main_slider .carousel-control-prev {
    width: 55px;
    height: 55px;
    background: #001431;
    opacity: 1;
    font-size: 16px;
    color: #ffffff;
    border-radius: 100px;
}

#main_slider .carousel-control-next:focus,
#main_slider .carousel-control-next:hover,
#main_slider .carousel-control-prev:focus,
#main_slider .carousel-control-prev:hover {
    color: #ffffff;
    background-color: #e73232
}


/* services section end */


/* studies section start */

.studies_section {
    width: 100%;
    float: left;
    padding-bottom: 100px;
}

.studies_taital {
    width: 100%;
    float: left;
    font-size: 40px;
    color: #001431;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 0px;
}

.studies_section_2 {
    width: 100%;
    float: left;
    padding: 40px 0px 0px 0px;
}

.studies_box {
    width: 100%;
    float: left;
    background-color: #ffffff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    height: auto;
    padding: 30px 10px;
    box-shadow: 0px -3px 15px 0px;
}


/* Zoom In #1 */

.hover01 figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    min-height: 370px;
}

.hover01 figure:hover img {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}

.column {
    margin: 15px 0px 0;
    padding: 0;
    box-shadow: 0px 10px 15px 0px;
}

.column:last-child {
    padding-bottom: 30px;
}

.column::after {
    content: '';
    clear: both;
    display: block;
}

figure {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: #fff;
    overflow: hidden;
}

figure:hover+span {
    bottom: -36px;
    opacity: 1;
}

.introduction_text {
    width: 100%;
    float: left;
    text-align: center;
    color: #001431;
    font-size: 20px;
    padding: 0px;
    text-transform: uppercase;
    font-weight: bold;
}

.read_bt {
    width: 170px;
    margin: 0 auto;
    text-align: center;
}

.read_bt a {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    background-color: #001431;
    margin-top: 30px;
    border-radius: 5px;
    text-transform: uppercase;
}

.read_bt a:hover {
    color: #ffffff;
    background-color: #e53232;
}

.read_bt.active a {
    color: #ffffff;
    background-color: #e53232;
}


/* studies section end */


/* about section start */
/* 
.about_section {
    width: 100%;
    float: left;
    background-color: #630708;
    height: auto;
    padding: 90px 0px 40px 0px;
} */
/* 
.about_text_main {
    width: 100%;
    float: left;
    padding: 100px 0px;
}

.about_taital {
    width: 100%;
    float: left;
    font-size: 40px;
    color: #ffffff;
    font-weight: bold;
}

.about_text {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #fefefd;
    margin: 30px 0px 0px 0px;
} */

.about_img {
    width: auto;
    margin-top: 30px;
}

.readmore_bt {
    width: 170px;
    float: left;
    margin-top: 50px;
}

.readmore_bt a {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #ffffff;
    background-color: #769f7a;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    text-transform: uppercase;
}

.readmore_bt a:hover {
    color: #ffffff;
    background-color: #001431;
}


/* about section end */


/* newsletter section start */

.newsletter_section {
    width: 100%;
    float: left;
}

.newsletter_taital_main {
    width: 100%;
    display: flex;
}

.newsletter_taital {
    width: 100%;
    float: left;
    font-size: 40px;
    color: #212120;
    font-weight: bold;
    text-transform: uppercase;
}

hr.newsletter_border_main {
    width: 70%;
    border: 1px solid #212120;
    margin-top: 25px;
}

.email_bt {
    color: #d9d9d8;
    width: 100%;
    height: 60px;
    font-size: 18px;
    background-color: #ffffff;
    padding: 15px 20px 10px 20px;
    border: 0px;
    margin-top: 40px;
    box-shadow: 0px 0px 11px 2px;
}

textarea#comment::placeholder {
    color: #d9d9d8;
}

.subscribe_bt {
    width: 180px;
    margin: 0 auto;
    text-align: center;
    margin-top: 40px;
}

.subscribe_bt a {
    width: 100%;
    float: left;
    font-size: 18px;
    color: #fefefd;
    background-color: #f9bc19;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    text-transform: uppercase;
}


/* newsletter section end */


/* testimonial section start */

.customer_section {
    width: 100%;
    float: left;
}

.customer_taital {
    width: 100%;
    float: left;
    font-size: 40px;
    color: #001431;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

.customer_section_2 {
    width: 100%;
    float: left;
    margin-top: 50px;
}

.box_main {
    width: 100%;
    float: left;
    background-color: #ffffff;
    padding: 0px 50px 0px 50px;
}

.customer_text {
    width: 100%;
    font-size: 14px;
    color: #737372;
    margin: 0 auto;
}

.quick_icon {
    float: right;
}

.customer_main {
    width: 100%;
    margin-top: 50px;
}

.customer_left {
    width: 30%;
    float: left;
}

.customer_right {
    width: 70%;
    float: left;
    margin-top: 40px;
}

.customer_name {
    width: 100%;
    float: left;
    font-size: 26px;
    font-weight: bold;
    color: #769f7a;
}

.enim_text {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #001431;
    margin: 20px 0px 0px 0px;
}

#my_slider a.carousel-control-prev {
    left: 30px;
    top: 190px;
}

#my_slider a.carousel-control-next {
    right: 30px;
    top: 190px;
}

#my_slider .carousel-control-next,
#my_slider .carousel-control-prev {
    width: 55px;
    height: 55px;
    background: #001431;
    opacity: 1;
    font-size: 18px;
    color: #ffffff;
    border-radius: 100%;
}

#my_slider .carousel-control-next:focus,
#my_slider .carousel-control-next:hover,
#my_slider .carousel-control-prev:focus,
#my_slider .carousel-control-prev:hover {
    color: #ffffff;
    background-color: #e73232
}


/* testimonial section end */


/* news section start */

.news_section {
    width: 100%;
    float: left;
}

.news_taital {
    width: 100%;
    float: left;
    font-size: 40px;
    text-transform: uppercase;
    color: #011430;
    font-weight: bold;
    text-align: center;
}

.news_section_2 {
    width: 98%;
    margin: 0 auto;
    text-align: center;
    background-color: #ffffff;
    height: auto;
    padding-bottom: 30px;
    border-radius: 5px;
}

.news_box {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 5px 5px 0px #e9e9e8;
    float: left;
    padding-bottom: 40px;
    margin-top: 30px;
}

.does_taital {
    width: 100%;
    float: left;
    font-size: 30px;
    color: #011430;
    text-align: center;
    font-weight: bold;
    padding-top: 60px;
}

.dummy_text {
    width: 90%;
    text-align: center;
    font-size: 16px;
    margin: 0 auto;
    color: #011430;
}

#custom_slider a.carousel-control-next {
    right: 0px;
    left: 0px;
    top: 480px;
}

#custom_slider .carousel-control-next,
#custom_slider .carousel-control-prev {
    width: 55px;
    height: 55px;
    background: #769f7a;
    opacity: 1;
    font-size: 18px;
    color: #ffffff;
    border-radius: 100%;
    margin: 0 auto;
    text-align: center;
}

#custom_slider .carousel-control-next:focus,
#custom_slider .carousel-control-next:hover,
#custom_slider .carousel-control-prev:focus,
#custom_slider .carousel-control-prev:hover {
    color: #ffffff;
    background-color: #e73232
}


/* news section end */


/* contact section start */

.contact_section {
    /* width: 100%;
    float: left;
    padding-bottom: 90px; */
}

.contact_taital {
    width: 100%;
    float: left;
    font-size: 40px;
    color: #011430;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 0px;
}

.contact_section_2 {
    width: 70%;
    margin: 0 auto;
    text-align: center;
}

.mail_section {
    width: 100%;
    float: left;
    margin-top: 30px;
}

.mail_text {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #7c7c7d;
    border-bottom: 1px solid #7c7c7d !important;
    background-color: transparent;
    padding: 14px 15px 0px 0px;
    margin-bottom: 10px;
    border: 0px;
}

input.mail_text::placeholder {
    color: #7c7c7d;
}

.massage-bt {
    color: #7c7c7d;
    width: 100%;
    height: 50px;
    font-size: 16px;
    color: #7c7c7d;
    border-bottom: 1px solid #7c7c7d !important;
    background-color: transparent;
    padding: 20px 15px 0px 0px;
    margin-bottom: 10px;
    border: 0px;
}

textarea#comment::placeholder {
    color: #7c7c7d;
}

.send_bt {
    width: 160px;
    margin: 0 auto;
    text-align: center;
}

.send_bt a {
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    background-color: #769f7a;
    padding: 10px;
    text-transform: uppercase;
    margin-top: 30px;
    display: block;
    font-weight: bold;
    border-radius: 5px;
}

.send_bt a:hover {
    color: #fff;
    background-color: #001431;
}


/* contact section end */


/* footer section start */

.footer_section {
    width: 100%;
    float: left;
    background-color: #001431;
    height: auto;
    padding: 80px 0px 30px 0px;
}

.footer_logo {
    width: 100%;
    text-align: center;
    margin: 40px 0px 0px 0px;
}

.social_icon {
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

.social_icon ul {
    margin: 0px;
    padding: 0px;
    display: inline-block;
    text-align: center;
}

.social_icon li {
    float: left;
    font-size: 30px;
    color: #ffffff;
    padding: 0px 20px;
}

.social_icon li a {
    color: #ffffff;
}

.social_icon li a:hover {
    color: #51a9e8;
}

.location_text {
    width: 100%;
    text-align: center;
    display: inline-block;
    margin: 0 auto
}

.location_text ul {
    margin: 0px;
    padding: 0px;
    display: inline-block;
}

.location_text li {
    float: left;
}

.location_text li a {
    float: left;
    font-size: 20px;
    color: #ffffff;
    margin: 0px 30px;
    background-color: #769f7a;
    padding: 12px 0px;
    border-radius: 100%;
    width: 60px;
    text-align: center;
}

.location_text li a:hover {
    color: #252525;
    background-color: #ffffff;
}


/* footer section end */


/* copyright section start */

.copyright_section {
    width: 100%;
    float: left;
    background-color: #001431;
    height: auto;
}

.copyright_text {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    margin-left: 0px;
}

.copyright_text a {
    color: #ffffff;
}

.copyright_text a:hover {
    color: #769f7a;
}


/* copyright section end */

.margin_top90 {
    margin-top: 90px;
}

/* layout */

.layout {
    background-color: #ffffff;
}

/* layout end */

.heading_text {
    color: #990708;
    margin-bottom: 20px;
    text-transform: uppercase;    
}

.heading_text:after {
    content: "";
    position: absolute;
    width: 60px;
    border: 2px solid rgb(255, 212, 27);
    border-radius: 2px;
    left: 0;
    right: 0;
    bottom: -5px;
    margin: 0 auto;
}

p {
    text-align: justify;
}